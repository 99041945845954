require("@rails/ujs").start();
// require("turbolinks").start();
// require("@rails/activestorage").start();
// require("channels");

global.$ = require("jquery");

import "popper.js";
import "bootstrap";

import "bootstrap-datepicker";
import "bootstrap-datepicker/dist/css/bootstrap-datepicker.css";
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.ru.js";
import "select2";
import "select2/dist/css/select2.css";
import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4.css";

import "../vue_apps/device_testings";
import "../vue_apps/user_device_models";
import "../vue_apps/analytics";
import "../vue_apps/analytics_by_author";

// styles
import "../assets/styles/app.scss";

$(document).ready(function () {
  $(".datepicker").datepicker({
    format: "yyyy-mm-dd",
    language: "ru",
  });

  $(".select2-autocomplete").select2({
    theme: "bootstrap4",
    allowClear: true,
    placeholder: "",
    templateSelection: templateResult,
    templateResult: templateResult,
  });
});

function templateResult(state) {
  let className = '';
  if(state.element) {
    className = state.element.className
  }
  let optionText = state.text.split(" ||| ");
  let leftText = optionText.slice(0, optionText.length - 1)
  let rightStyledText = optionText[optionText.length - 1]
  return $(`
    <span>${leftText}</span>
    <span class="${className}">${rightStyledText}</span>
  `)
}

$(function () {
  $('[data-toggle="popover"]').popover();
});

// $(function () {
//   $('[data-toggle="tooltip"]').tooltip()
// })

// function onDocumentReady() {
//   $(function () {
//     $('[data-toggle="popover"]').popover()
//   })
// }

// $(onDocumentReady);
